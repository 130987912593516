import PropTypes from "prop-types";

function SectionTitle({ children, className = "" }) {
  return <h2 className={`h2 h2--bold h2--uc ${className}`}>{children}</h2>;
}

SectionTitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default SectionTitle;
