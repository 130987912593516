import { useState, useEffect } from "react";
import Order from "../accountPage/orderHistory/Order";
import { ALLoading } from "../ALComponents";
import useOrderHistory from "../../hooks/useOrderHistory";

import * as Styles from "./billingHistory/styles.module.scss";

const INITIAL_ORDER_COUNT = 2;
const PAGE_SIZE = 20;

function BillingHistory() {
  const [showPreviewOrders, setShowPreviewOrders] = useState(true);
  const [page, setPage] = useState(0);
  const [reachedEnd, setReachedEnd] = useState(false);
  const { orders, isLoading } = useOrderHistory(page, "subscription");

  useEffect(() => {
    if (orders.length < (page + 1) * PAGE_SIZE) {
      setReachedEnd(true);
    } else if (reachedEnd === true && orders.length === (page + 1) * 20) {
      setReachedEnd(false);
    }
  }, [orders, page, reachedEnd]);

  const showMoreButton =
    !reachedEnd && orders.length > INITIAL_ORDER_COUNT ? (
      <button
        onClick={() => {
          if (showPreviewOrders) {
            setShowPreviewOrders(false);
          } else {
            setPage(page + 1);
          }
        }}
        className={Styles.toggleView}
        type="button"
      >
        See more
      </button>
    ) : null;

  const showLessButton = !showPreviewOrders ? (
    <button
      onClick={() => {
        if (page === 0) {
          setShowPreviewOrders(true);
        } else {
          setPage(Math.max(0, page - 1));
        }
      }}
      className={Styles.toggleView}
      type="button"
    >
      See less
    </button>
  ) : null;

  return (
    <div className={Styles.container}>
      <h2 className="h2 h2--bold h2--uc">Billing History</h2>

      {isLoading && (orders || []).length === 0 && <ALLoading />}

      {!isLoading && (
        <>
          <div className={Styles.orders}>
            {orders
              .slice(0, showPreviewOrders ? INITIAL_ORDER_COUNT : orders.length)
              .map((order) => (
                <Order key={order.node.name} order={order.node} orderHasSubscription />
              ))}
          </div>
          {showMoreButton}
          {showLessButton}
        </>
      )}
    </div>
  );
}

export default BillingHistory;
