import SEO from "../components/seo";
import LuxePage from "../components/Luxe";

function Luxe() {
  return (
    <>
      <SEO title="Ana Luisa Luxe" description="TODO: Add LUXE description" />
      <LuxePage />
    </>
  );
}

export default Luxe;
