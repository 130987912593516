import useDeviceDetect from "../hooks/useDeviceDetect";
import Accordion from "./al_components/ALAccordion";

import "./faqLuxe/styles.scss";

function FaqLuxe() {
  const { isMobile } = useDeviceDetect();
  return (
    <div>
      <h2 className="luxe_faq_title h2 h2--bold h2--uc">
        {isMobile ? "FAQ" : "Frequently Asked Questions"}
      </h2>
      <div>
        <Accordion title="What is AL Luxe?" isOpen>
          <div className="luxe_faq_answer subtext">
            <p>
              AL Luxe is Ana Luisa's exclusive membership program. This subscription-based
              membership offers total shopping freedom and a long list of perks – buildable store
              credit, additional discounts, and priority customer support – all for $39.99 a month
              (plus tax).
            </p>
            <p>
              Becoming Luxe is simple! You'll see the option to sign up from our shopping cart,
              along with the exclusive membership pricing. Once your order is confirmed, you'll be
              charged a $0.95 one-time trial fee for your first month of membership. After the first
              month, you'll be charged $39.99 (plus tax). Pause, skip, or cancel your membership at
              any time – it really is that easy!
            </p>
          </div>
        </Accordion>
        <Accordion title="What is included in my AL Luxe membership?">
          <div className="luxe_faq_answer subtext">
            <p>Discount Perks</p>
            <p>
              - Buy More, Save More: Receive an additional 5% off during our site-wide promotions
            </p>
            <p>
              - Receive 5% Off Solid Gold Products: Exclusive to AL Luxe members (this the only way
              to save on our fine jewelry line!)
            </p>
            <p>- Free Shipping: All your orders ship free (yes, all of them)</p>
            <br />
            <p>Sale Perks</p>
            <p>
              - Early Access: Receive notifications about our sales and new collections, and get the
              chance to shop before anyone else
            </p>
            <p>
              - Members-Only Sales: More sales throughout the year, exclusively catered just for you
            </p>
            <p>- $$$ Off: Use your credit to unlock money off future purchases</p>
            <p>
              - Priority Customer Support: Need help with your order? We’ll respond faster and offer
              a more personalized experience
            </p>
          </div>
        </Accordion>
        <Accordion title="Does AL Luxe have a monthly fee?">
          <div className="luxe_faq_answer subtext">
            <p>
              Yes! For your first month of membership, you will be billed a one-time trial fee of
              $0.95 when you sign up. After that, you will be charged $39.99 (plus tax) on a monthly
              basis. Your store credit will be updated automatically, and you can use your balance
              to make purchases right away.
            </p>
            <p>
              If you have signed up for AL Luxe by mistake, please contact our team at
              <a href="mailto:love@analuisa.com">love@analuisa.com</a>. Refund requests must be made
              within 30 days of the charge.
            </p>
          </div>
        </Accordion>
        <Accordion title="Do I have to shop every month as a member of AL Luxe?">
          <div className="luxe_faq_answer subtext">
            <p>
              Absolutely not! The AL Luxe membership is flexible, so you don't need to make a
              purchase every month to maintain your member status. Your credit doesn't expire, so
              feel free to spend or save it however you like.
            </p>
          </div>
        </Accordion>
        <Accordion title="I'd like to cancel my membership.">
          <div className="luxe_faq_answer subtext">
            <p>
              We’ll miss you, but we understand some things don’t last forever. To cancel your AL
              Luxe membership, follow these steps:
            </p>
            <p>1. Log into your account.</p>
            <p>
              2. Click the button labeled &quot;Cancel Membership&quot; listed underneath your
              personal details.
            </p>
            <p>
              3. A pop-up will appear on the page. Follow the prompts on the screen to tell us a
              little bit about why you are ending your membership and finalize the cancellation.
            </p>
            <p>
              In addition to canceling, you have the ability to pause your membership at any time
              for up to three months. The steps to pause your membership are the same as canceling
              (You will see the option for both in the pop-up). If you decide to pause your
              membership, your membership will automatically renew at the end of the pause period.
            </p>
          </div>
        </Accordion>
      </div>
    </div>
  );
}

export default FaqLuxe;
