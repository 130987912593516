import { useContext } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { GeoContext } from "../context/GeoProvider";
import { useALError } from "../helpers/ErrorBoundary/ALErrorBoundary";
import { getCookie } from "../context/helpers";
import { getShopifyStoreByCountry } from "../../locale-shopifies";

const CACHE_AVAILABILITY = 5 * 60 * 1000; // 5 minutes

function useOrderHistory(page, type) {
  const { sendReport } = useALError();

  const { gePriceDetails, isLoadingCountry } = useContext(GeoContext);
  const countryCode = gePriceDetails?.CountryCode;
  const store = getShopifyStoreByCountry(countryCode);

  const accessToken = getCookie(`${store.name}-accessToken`);

  const { data, isLoading } = useQuery({
    queryKey: ["order-history", { type, page, countryCode }],
    queryFn: () =>
      axios.get(
        `/.netlify/functions/get-orders?type=${type}&page=${page}&countryCode=${countryCode}&accessToken=${accessToken}`
      ),
    keepPreviousData: true,
    staleTime: CACHE_AVAILABILITY,
    enabled: !!countryCode && !isLoadingCountry,
    useErrorBoundary: true,
    meta: { name: "useOrderHistory", priority: "P2", sendReport },
  });

  return {
    orders: Object.values(data?.data?.orders || {}),
    isLoading,
  };
}

export default useOrderHistory;
