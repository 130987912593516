import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Popup } from "../../Popup";
import { ALButton } from "../../ALComponents";

import IconClose from "../../icons/svgs/close-round.svg";
import IconCheck from "../../icons/svgs/check.svg";

import "./styles-modal.scss";

function SkipSubscriptionModal({ isOpen, subscriptionStatus, onAction = () => {}, onClose }) {
  const [isSubscriptionSkipped, setIsSubscriptionSkipped] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (subscriptionStatus === "ACTIVE") {
      setIsSubscriptionSkipped(false);
    }
  }, [subscriptionStatus]);

  const onSkipSubscription = async () => {
    setIsLoading(true);

    onAction()
      .then(() => {
        setIsSubscriptionSkipped(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Popup
      open={isOpen}
      modal
      closeOnDocumentClick
      className="subscription-modal"
      onClose={onClose}
    >
      <button type="button" className="close-icon" onClick={onClose}>
        <IconClose height="30" width="30" fill="#FFF" stroke="#2d2927" />
      </button>
      <h3 className="h3 h3--bold">Not into it this month?</h3>
      <p className="subtext mb-40">
        Your membership is flexible - simply skip payment and pause your membership for a month.
      </p>
      <ALButton
        size="medium"
        variant="primary"
        fullWidth
        onClick={onSkipSubscription}
        disabled={isLoading || isSubscriptionSkipped}
        isLoading={isLoading}
      >
        Confirm Skip
      </ALButton>

      {isSubscriptionSkipped && (
        <div className="mt-10 subscription-confirmation">
          <IconCheck height="30" width="30" fill="#FFF" stroke="#2d2927" className="mr-5" />
          <span className="subtext">Success!</span>
        </div>
      )}
    </Popup>
  );
}

SkipSubscriptionModal.propTypes = {
  isOpen: PropTypes.bool,
  subscriptionStatus: PropTypes.string,
  onAction: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

export default SkipSubscriptionModal;
