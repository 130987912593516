// extracted by mini-css-extract-plugin
export var awesome = "styles-module--awesome--66e3c";
export var bold = "styles-module--bold--da201";
export var center = "styles-module--center--1a212";
export var container = "styles-module--container--56824";
export var h1 = "styles-module--h1--da5ce";
export var h1Bold = "styles-module--h1--bold--94a38";
export var h1Uc = "styles-module--h1--uc--64da1";
export var h2 = "styles-module--h2--9c46b";
export var h2Bold = "styles-module--h2--bold--58cec";
export var h2Uc = "styles-module--h2--uc--236f6";
export var h3 = "styles-module--h3--3037a";
export var h3Bold = "styles-module--h3--bold--cb1ea";
export var h3Uc = "styles-module--h3--uc--e3d69";
export var h4 = "styles-module--h4--f5274";
export var h4Bold = "styles-module--h4--bold--b5db1";
export var h4Uc = "styles-module--h4--uc--ce67b";
export var linkUnderline = "styles-module--link--underline--37700";
export var microtext = "styles-module--microtext--9c92f";
export var microtextBold = "styles-module--microtext--bold--2ba72";
export var microtextUc = "styles-module--microtext--uc--5dfce";
export var orders = "styles-module--orders--95965";
export var strikethrough = "styles-module--strikethrough--d3c31";
export var strikethroughLg = "styles-module--strikethrough--lg--6fd70";
export var strikethroughSm = "styles-module--strikethrough--sm--6431f";
export var subtext = "styles-module--subtext--f354f";
export var subtextBold = "styles-module--subtext--bold--2d099";
export var subtextUc = "styles-module--subtext--uc--7e8f2";
export var text = "styles-module--text--d3588";
export var textBold = "styles-module--text--bold--20003";
export var textUc = "styles-module--text--uc--2678e";
export var titleL = "styles-module--title-l--b524d";
export var titleM = "styles-module--title-m--1e343";
export var titleS = "styles-module--title-s--48af0";
export var titleXl = "styles-module--title-xl--10cc2";
export var titleXs = "styles-module--title-xs--1f08b";
export var titleXxs = "styles-module--title-xxs--af903";
export var toggleView = "styles-module--toggle-view--b707d";
export var uc = "styles-module--uc--9c0ad";
export var underline = "styles-module--underline--c16c2";